import { memo, useEffect } from "react";

type RedirectToDocumentProps = {
    linkToDocument:string;
}

function RedirectToDocument(props:RedirectToDocumentProps) {

    useEffect(() => {
        if (props.linkToDocument) {
            window.location.replace(props.linkToDocument);
        }
    }, [props.linkToDocument]);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        }}>
            <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    fontSize: '1.2rem'
                }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem'
                }}>
                    <span>Redirecting to <a href={props.linkToDocument}>{props.linkToDocument}</a>...</span>
                    <span>Click the link to manually redirect if this does not happen automatically.</span>
                </div>
            </div>
        </div>
    );
}
export default memo(RedirectToDocument);